import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
	OnlineCoursesDetailsSection,
  OnlineCoursesDetailsContent,
	ContentRow,
	ContentColumn,
  Title,
  Paragraph,
	ButtonContainer,
  OnlineCoursesDetailsButton
} from './OnlineCoursesDetailsStyles';

// Information rendering & apply CSS 
const OnlineCoursesDetails = ({ onlineCoursesDetailsParentByLanguage }) => {

  return (
    <>
      <OnlineCoursesDetailsSection>
        <OnlineCoursesDetailsContent>
          <Title>
            I miei corsi stanno arrivando!
          </Title>

          <ContentColumn>
            <ContentRow>
              <Paragraph>
                Sto preparando contenuti esclusivi per aiutarti a migliorare le tue competenze su Bitcoin e Smart Contract.
              </Paragraph>
            </ContentRow>
            <ContentRow>
              <Paragraph>
                Contattami per essere tra i primi a sapere quando saranno disponibili!
              </Paragraph>
            </ContentRow>
            <ContentRow>
              <ButtonContainer>
                  <Link to="/contact">
                    <OnlineCoursesDetailsButton>
                      Contattami
                    </OnlineCoursesDetailsButton>
                  </Link>
              </ButtonContainer>
            </ContentRow>
          </ContentColumn>
        </OnlineCoursesDetailsContent>
      </OnlineCoursesDetailsSection>
    </>
  );
};

export default OnlineCoursesDetails;
