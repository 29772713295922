import React, { useState, useEffect } from 'react';
import OnlineCoursesDataService from "../services/onlineCoursesService";

//Components
import OnlineCoursesDetails from '../components/OnlineCoursesDetails/OnlineCoursesDetails';

const OnlineCourses = ({ language }) => {

  const [onlineCourses, setOnlineCourses] = useState([]);
  const [onlineCoursesByLanguage, setOnlineCoursesByLanguage] = useState({});

  // [] ==> Only once when loading the component...
  useEffect(() => {
    retrieveOnlineCourses();
  }, []);

  // ... retrieve all OnlineCourses structure.
  const retrieveOnlineCourses = () => {
    OnlineCoursesDataService.getAll()
      .then(response => {
        setOnlineCourses(response.data);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  // [language, onlineCourses] ==> Whenever the "language" and/or "onlineCourses" state variables change...
  useEffect(() => {
    getCurrentObjectByLanguage();
  }, [language, onlineCourses]);

  // ... the "currentObjectByLanguage" status variable is updated.
  const getCurrentObjectByLanguage = () => {
    // Filter by language: from Array to Array
    const currentObjectAsArray = onlineCourses.filter(item => item.language == language);
    // Deconstructs the array: from Array to Object
    const currentObject = Object.assign({}, ...currentObjectAsArray);
    // The state variable now is manipulable by fields!
    setOnlineCoursesByLanguage(currentObject);
  }
  
  return (
    <>
      <OnlineCoursesDetails contactParentByLanguage={onlineCoursesByLanguage} />
		</>
  )
}

export default OnlineCourses;