import http from "../httpCommon";

class OnlineCoursesDataService {
  getAll() {
    return http.get("/onlineCourses");
  }

  getByLanguage(lang) {
    return http.get(`/onlineCourses/${lang}`);
  }
}

export default new OnlineCoursesDataService();
