import styled from 'styled-components';
import { Section } from '../../globalStyles';

export const OnlineCoursesDetailsSection = styled(Section)`
	padding: 3rem 0 0;
`;

export const OnlineCoursesDetailsContent = styled.div`
	width: 100%;
	padding: 0 1rem;
	z-index: 10;
	position: relative;
	min-height: calc(100vh - 325px);
`;

export const Title = styled.h2`
	margin: 1rem 0rem 2rem;
	font-size: clamp(1.875rem, 2.7vw, 2.25rem);
	line-height: 1;
	font-weight: 500;
	text-align: center;
`;

export const Paragraph = styled.p`
	font-weight: 400;
	font-size: clamp(1rem, 1.45vw, 1.25rem);
	font-style: italic;
	text-align: center;
`;

export const ContentColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
`;

export const ContentRow = styled.div`
	display: flex;
	flex-direction: row;
	padding-bottom: 1rem;
	justify-content: center;
`;

export const ButtonContainer = styled.div`
	padding: 2rem 0;
`;

export const OnlineCoursesDetailsButton = styled.button`
	height: 3rem;
	padding: 0.8rem 2rem;
	font-weight: 600;
	font-size: 1.1rem;
	border-radius: 1.5rem;
	border: none;
	background: linear-gradient(150deg, #ccc -10%, #636363);
	cursor: pointer;
	color: white;

	&:hover {
		box-shadow: 0 0 2rem 0.75rem #666;
		transition: box-shadow 0.4s ease-in;

		&:disabled {
      cursor: auto;
      box-shadow: none;
    }
	}
`;
